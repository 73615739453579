import React from "react";
import Header from "./Header/Header";
import SEO from "./seo";

export default function Layout(props: {
    children: React.ReactNode;
    title?: string;
}) {
    const { children, title } = props;
    return (
        <>
            <SEO title={title}/>
            <Header />
            <main id="maincontent" aria-label="content">{children}</main>
        </>
    );
}
