// extracted by mini-css-extract-plugin
export const navHeader = "header-module--navHeader--3DH-l";
export const logoImage = "header-module--logoImage--1hR93";
export const navLi = "header-module--nav-li--1H0uq";
export const logoTitle = "header-module--logoTitle--3K4uF";
export const titleUpper = "header-module--titleUpper--2iRYF";
export const titleLower = "header-module--titleLower--HA8Cv";
export const activeLink = "header-module--activeLink--heU2k";
export const logo = "header-module--logo--1f9Le";
export const navItems = "header-module--navItems--ebhIy";
export const navMain = "header-module--navMain--3ZwSR";
export const hamburger = "header-module--hamburger--1t_Bd";
export const skipLinks = "header-module--skipLinks--2dUcA";