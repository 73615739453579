import React, { useState } from "react";
import * as headerStyles from "./header.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { GiHamburgerMenu } from "react-icons/gi";

export default function Header() {
    const [isMenuVisible, setIsMenuVisible] = useState(true);

    function toggleMenuVisibility() {
        setIsMenuVisible((prevState) => !prevState);
    }

    return (
        <>
        <a href="#maincontent" className={`${headerStyles.skipLinks}`}>Skip to main content</a>
            <nav
                className={`${headerStyles.navHeader} shadow ${headerStyles.navMain} `}
                aria-label="site"
            >
                <Link to="/" aria-label="home">
                    <div className={`d-flex ${headerStyles.logo}`}>
                        <div
                            className={`${headerStyles.logoImage} d-flex align-items-center`}
                        >
                            <StaticImage
                                src="../../images/comicLogo.svg"
                                alt="Comic Logo"
                            />
                        </div>

                        <div
                            className={`d-flex flex-column ${headerStyles.logoTitle}`}
                        >
                            <span className={`${headerStyles.titleUpper}`}>
                                Comic Book
                            </span>
                            <span className={`${headerStyles.titleLower}`}>
                                Grading Tool
                            </span>
                        </div>
                    </div>
                </Link>
                {true && isMenuVisible ? (
                    <ul className={`${headerStyles.navItems}`}>
                        <li>
                            <Link
                                to="/"
                                activeClassName={`${headerStyles.activeLink}`}
                            >
                                Dashboard
                            </Link>
                        </li>
                        <li>Grading Comics</li>

                        <li>
                            <Link
                                to="/roadmap"
                                activeClassName={`${headerStyles.activeLink}`}
                            >  
                                Roadmap
                            </Link>
                        </li>
                    </ul>
                ) : (
                    ""
                )}

                <GiHamburgerMenu
                    className={`position-absolute ${headerStyles.hamburger}`}
                    style={{ top: 20, right: 20, fontSize: 20 }}
                    onClick={toggleMenuVisibility}
                />
            </nav>
        </>
    );
}
